import React from 'react';

import * as Dto from "actions/Dto";
import * as ProjectActions from "actions/ProjectActions";
import Routes from "actions/Routes";
import Glyphicon from "components/general/Glyphicon";
import * as Utils from "components/general/Utils";
import Remarkable from "remarkable";

import logger from 'actions/Logger';
import SectionBody from 'components/layouts/SectionBody';
import { TopMenuItem } from 'components/layouts/navigation/TopMenuItem';
import ReactGA from 'react-ga';
import SessionStore from "store/SessionStore";
import { AssignmentBriefEditForm } from '../users/AssignmentBriefEditForm';
import Assignment from './Assignment';
import AssignmentEditForm from './AssignmentEditForm';
import { AssignmentSubscribersForm } from "./AssignmentSubscribersForm";
import ProjectEditForm from "./ProjectEditForm";

interface ProjectEditPageState {
	accountId: number | null,
	data: Dto.ProjectDto,
	assignments: Dto.PagedListDto<Dto.AssignmentDto>, 
	editAssignment?: Dto.AssignmentDto | null,
	editAssignmentBrief?: Dto.AssignmentDto | null,
	editSubscribers?: Dto.AssignmentDto | null,
	errors?: Dto.ErrorDto[]  | null, 
	messages?: Dto.MessageDto[]  | null, 
	timeEntrySummaries?: Dto.TimeEntrySummaryDto[]
	uploading: boolean, 
	projectId: string, 
	edit: string | null
}

export default class ProjectEditPage extends React.Component<{}, ProjectEditPageState> {
	
	private path: string;

	constructor(props) {
		super(props);
		this.state = {
			accountId: SessionStore.getAccountId(),
			data: {	 } as Dto.ProjectDto,
			assignments: { values: [] as Dto.AssignmentDto[], index: 0, size: 0, total: 0, pages: 0 }, 
			editAssignment: null, editAssignmentBrief: null,
			errors: null, messages: null, uploading: false, projectId: props.match.params.projectId, edit: null
		};

		this.path = Routes.project(props.match.params.projectId);
	}

	componentWillMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(this.path);	//analytics

		this.loadData();

	}

	loadData() {
		ProjectActions.project(this.state.accountId, this.state.projectId,
			(data) => {
				this.loadAssignments();
				this.loadTimeEntrySummary();
				this.setState({data: data, errors: null, messages: null})
			},
			(errors) => this.setState({errors: errors})
		);
	}

	loadTimeEntrySummary() {
		ProjectActions.projectTimeEntrySummary(this.state.accountId, this.state.projectId,
			(data) => this.setState({timeEntrySummaries: data, errors: null, messages: null}),
			(errors) => this.setState({errors: errors})
		);
	}

	loadAssignments() {
		ProjectActions.assignmentsByProject(this.state.accountId, this.state.projectId, 0,
			(data) => this.setState({assignments: data, errors: null, messages: null}),
			(errors) => this.setState({errors: errors})
		);
	}

	loadMoreAssignments() {
		ProjectActions.assignmentsByProject(this.state.accountId, this.state.projectId, this.state.assignments.index + 1,
			(data) => this.handleLoadMoreAssignments(data),
			(errors) => this.setState({errors: errors})
		);
	}

	handleLoadMoreAssignments(data) {
		data.values = this.state.assignments.values.concat(data.values);
		this.setState({assignments: data, errors: null, messages: null});
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	handleChange(event) {
		this.setState({errors:null, messages: null});
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({data: data});

	}

	handleSubmit(project) {

		ProjectActions.projectUpdate(this.state.accountId, this.state.projectId, project,
			(data) => this.setState({data: data, errors: null, messages: null, edit: null}),
			(errors) => this.setState({errors: errors})
		)
	}

	handleCreateAssignment(assignment: Dto.AssignmentDto) {

		if (!assignment.user) {
			this.setState({errors: [{message: "An assignment requires a Team Member to be selected.", code: "error"}]});
			return;
		}

		ProjectActions.assignmentAdd(this.state.accountId, this.state.projectId, {...assignment, userId: assignment.user.id},
			(assignment) => {
				let assignments = { ...this.state.assignments };
				assignments.values.push(assignment);
				this.setState({assignments: assignments, errors: null, messages: [{ message: "Assignment created" }], edit: null})
			},
			(errors) => this.setState({errors: errors})
		)
	}

	handleAssignmentEdit(assignment) {
		if (SessionStore.isWriteAccessToProject(assignment.project)) {
			this.setState({edit: "edit-assignment", editAssignment: {...assignment}})
		}
	}


	handleAssignmentBriefEdit(assignment) {
		if (SessionStore.isWriteAccessToProject(assignment.project) || assignment.user.id === SessionStore.userId) {
			this.setState({edit: "edit-assignment-brief", editAssignmentBrief: {...assignment}})
		}
	}

	handleUpdateAssignment(assignment) {

		ProjectActions.assignmentUpdate(this.state.accountId, assignment.id, {userId: assignment.user.id, ...assignment},
			(result) => {

				this.replaceAssignmentInState(result);
				this.setState({errors: null, messages: null, edit: null, editAssignment: null});

			},
			(errors) => this.setState({errors: errors})
		);

	}

	handleUpdateAssignmentBrief(assignment: Dto.AssignmentDto) {

		this.replaceAssignmentInState(assignment);
		this.unedit();

	}

	handleUpdateSubscribers(assignment: Dto.AssignmentDto) {

		this.replaceAssignmentInState(assignment);
		this.unedit();

	}

	handleAssignmentDelete(assignment: Dto.AssignmentDto) {

		ProjectActions.assignmentDelete(this.state.accountId, assignment.id,
			(result) => {

				this.replaceAssignmentInState(result);
				this.setState({errors: null, messages: [{ message: "Assignment deleted" }], edit: null, editAssignment: null});

			},
			(errors) => this.setState({errors: errors})
		);

	}

	handleSubscribersEdit(assignment: Dto.AssignmentDto) {

		this.setState({editSubscribers: assignment});

	}

	replaceAssignmentInState(assignment: Dto.AssignmentDto) {

		//update in-place assignment
		let assignmentsClone = [...this.state.assignments.values];
		const index = assignmentsClone.findIndex(nextAssignment => nextAssignment.id === assignment.id);
		
		if (index >= 0) {
			assignmentsClone[index] = assignment;
		} else {
			assignmentsClone.push(assignment);
		}
		let assignments = this.state.assignments;
		assignments.values = assignmentsClone;
		this.setState({assignments: assignments, data: assignment.project});

	}

	unedit() {
		this.setState({edit: null, editAssignment: null, editAssignmentBrief: null, editSubscribers: null });
	}

	editProject() {
		this.setState({edit: "edit-project"});
	}

	deleteProject() {
		if (window.confirm("Delete this project?")) {
			ProjectActions.projectDelete(this.state.accountId, this.state.projectId,
				(data) => this.setState({data: data, errors: null, messages: null, edit: null}),
				(errors) => this.setState({errors: errors})
			)
		}
	}

	createAssignment() {
		this.setState({edit: "create-assignment"});
	}

	createNewAssignment() {
		const now = new Date();
		const nextMonth = Utils.datePlusMonths(now, 1);

		return { 
			startDate: now, endDate: nextMonth,
			brief: {
				author:  SessionStore.getSetting(Dto.SettingName.ASSIGNMENT_BRIEF_AUTHOR_DEFAULT) || Dto.AssignmentBriefAuthor.SELF,
				state: SessionStore.getSetting(Dto.SettingName.ASSIGNMENT_BRIEF_STATE_DEFAULT) || Dto.AssignmentBriefState.NONE
			}
		} as Dto.AssignmentDto 
	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	renderTitle() {
		if (this.state.data.title == null) return (<h1>Edit Project</h1>);
		return <h1>{this.state.data.title}</h1>;
	}

	render() {

		if (!this.state.data) {
			return <section className="project-edit"></section>;
		}

		document.title = (this.state.data.title ? this.state.data.title : "Project");
		
		//Sort
		const sortedAssignments = [...this.state.assignments.values].sort((a, b) => {
			const a_name = a.user.firstname + " " + a.user.surname + " / " + a.title;
			const b_name = b.user.firstname + " " + b.user.surname + " / " + b.title;
			return a_name.localeCompare(b_name);
		});

		logger.debug(this.state);
		const assignments = sortedAssignments.map((assignment, index) => {

			return <Assignment key={assignment.id} assignment={assignment} project={this.state.data}
				timeEntrySummaries={this.state.timeEntrySummaries || []} index={index}
				onEdit={() => this.handleAssignmentEdit(assignment)}
				onEditBrief={() => this.handleAssignmentBriefEdit(assignment)}
				onDelete={() => this.handleAssignmentDelete(assignment)}
				onEditSubscribers={() => this.handleSubscribersEdit(assignment)}
				/>

		});

		//pagination
		let pagination = <></>;
		if (this.state.assignments.pages > this.state.assignments.index + 1) {
			pagination = (
				<nav className="pagination-nav">
					<ul className="pagination">
						<li className="page-item"><button className="page-link" onClick={(e) => this.loadMoreAssignments()}>Load more...</button></li>
					</ul>
					Showing {this.state.assignments.values.length} of {this.state.assignments.total}
				</nav>
			);
		}

		let markdown = new Remarkable();

		//Calculate budget amounts
		var actualSpend = 0.0;

		if (this.state.timeEntrySummaries) {
			this.state.timeEntrySummaries.forEach((entry) => {
				let billableAmount = entry.billableAmount;
				if (!isNaN(billableAmount)) actualSpend = billableAmount + actualSpend;
			});
		}

		let budget = isNaN(this.state.data.budget) ? 0 : this.state.data.budget;

		var forecastSpend = 0.0;

		//iterate assignment to calculate stuff
		if (this.state.assignments && this.state.assignments.values) {
			this.state.assignments.values.forEach((assignment) => {
				forecastSpend = forecastSpend + assignment.forecastSpend;
			});
		}

		let variance = budget - Math.max(forecastSpend, actualSpend);

		let topMenuItems = (
			<React.Fragment>
				<TopMenuItem label={"/ " + this.state.data.title} to={Routes.project(this.state.data.id)} active={true} />
			</React.Fragment>
		)

		return (

			<SectionBody className="project-edit" topMenuItems={topMenuItems}
				onNotificationClose={() => this.setState({errors: null, messages: null})}
				errors={this.state.errors} messages={this.state.messages} >

				<ProjectEditForm data={this.state.data} isOpen={this.state.edit === "edit-project"} title="Edit Project"
					onCancel={() => this.unedit()} 
					onConfirm={(project) => this.handleSubmit(project)}
					onDelete={() => this.deleteProject()}
				/>

				<div className="project-info">
					<div className="project-header">
						<h1>{this.state.data.title}</h1>

						<div className="controls">

							<React.Fragment>
								<div className="dropdown">
									<button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
										<Glyphicon name="cogwheel" className="invert" />
									</button>
									<div className="dropdown-menu dropdown-menu-right">
										<button className="dropdown-item" onClick={(e) => this.editProject()}>Edit Project</button>
										<a className="dropdown-item" rel="noopener noreferrer" href={Routes.projectTimesheetsReport(this.state.accountId, this.state.projectId)} target="_blank" >Download Timesheets</a>
									</div>
								</div>


							</React.Fragment>
						</div>
					</div>

					<div className="project-details">

						<div className="project-info">
							<h2>{this.state.data.organisation}</h2>
							{this.state.data.status !== Dto.ProjectStatus.APPROVED ? <div><label>Status:</label> <span className="setting-value">{this.state.data.status}</span></div> : null}
							{ this.state.data.location ? <div><label>Location:</label> <span className="setting-value">{this.state.data.location}</span></div> : null }
							{ this.state.data.externalId ? <div><label>External Id:</label> <span className="setting-value">{this.state.data.externalId}</span></div> : null }
							{ this.state.data.uuid ? <div><label>UUID:</label> <span className="setting-value">{this.state.data.uuid}</span></div> : null }
							{ this.state.data.code ? <div><label>Project Id:</label> <span className="setting-value">{this.state.data.code}</span></div> : null }
							<div className="project-date"><label>Start:</label> <span className="date">{Utils.formatDateWithMonth(this.state.data.startDate)}</span></div>
							<div className="project-date"><label>End:</label> <span className="date">{Utils.formatDateWithMonth(this.state.data.endDate)}</span></div>
						</div>

						{ this.state.data.budget ?
							<div className="project-financials">
								<div className="project-kpi budget"><label>Budget:</label><span className="project-kpi-value">{Utils.formatMoney(budget)}</span></div>
								<div className="project-kpi forecast"><label>Forecast:</label><span className="project-kpi-value">{Utils.formatMoney(forecastSpend)}</span>{forecastSpend < actualSpend ? <div className="minor-text error">Forecast is less than actual</div> : null}</div>
								<div className="project-kpi actual"><label>Actual:</label><span className="project-kpi-value">{Utils.formatMoney(actualSpend)}</span></div>
								<div className={"project-kpi variance" + (variance > 0 ? " ok" : " bad")}><label>Variance:</label><span className="project-kpi-value">{Utils.formatMoney(variance)}</span></div>
							</div>
 						: null }
					</div>



					<div className="project-description">
						{this.state.data.description ?
							<p dangerouslySetInnerHTML={{ __html: markdown.render(this.state.data.description)}} /> :
							<p></p>
						}
					</div>
				</div>

				<div className="assignments">

					<h4>Assignments</h4>

					<div className="grid">

						<div className="assignment header" >

							<div className="index"></div>
							<div className="thumbnail"></div>
							<h5 className="name">Name</h5>
							<div className="status-icons"></div>
							<div className="status-pills">Status</div>

							<div className="start-date">Start Date</div>
							<div className="end-date">End Date</div>
							<div className="duration">Duration</div>
							<div className="effort">Forecast</div>
							<div className="booked">Booked</div>
							{SessionStore.isWriteAccessToProject(this.state.data) ?
								<React.Fragment>
									<div className="rate">Rate</div>
									<div className="billable">Billable</div>
								</React.Fragment>
								: null
							}
						</div>

						{assignments}

						{SessionStore.isWriteAccessToProject(this.state.data) ?
							<div className="assignment add-new">
								<button className="add-label" onClick={() => this.setState({edit: "create-assignment"})}><Glyphicon name="plus" className="grey" label="Add Assignment"/></button>
								{this.state.data.id ?
									<AssignmentEditForm title="Add Assignment" key={"assignment-create-form"}
										isOpen={this.state.edit === "create-assignment"} onCancel={() => this.unedit()}
										onConfirm={(assignment) => this.handleCreateAssignment(assignment)}
										project={this.state.data} assignment={this.createNewAssignment()} />
									: null
								}
							</div>
							: null
						}



						{this.state.editAssignment ?
							<AssignmentEditForm title="Add Assignment" key={"assignment-edit-form"}
								isOpen={this.state.editAssignment !== null} onCancel={() => this.unedit()}
								onConfirm={(assignment) => this.handleUpdateAssignment(assignment)}
								project={this.state.data} assignment={this.state.editAssignment} />

							: null
						}

						{this.state.editAssignmentBrief ?
							<AssignmentBriefEditForm 
								title="Assignment Brief"
								isOpen={this.state.editAssignmentBrief !== null}
								assignment={this.state.editAssignmentBrief}
								onCancel={() => this.unedit()}
								onError={(errors) => this.setState({errors: errors})}
								onUpdated={(assignment) => this.handleUpdateAssignmentBrief(assignment)}
							/>
							: null
						}

						{this.state.editSubscribers ?
							<AssignmentSubscribersForm 
								assignment={this.state.editSubscribers}
								onCancel={() => this.unedit()}
								onError={(errors) => this.setState({errors: errors})}
								onUpdated={(assignment) => this.handleUpdateSubscribers(assignment)}
							/>
							: null
						}


						{pagination}

					</div>
				</div>

			</SectionBody>

		);
	}
}
